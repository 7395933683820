import Typography from 'typography'
import {css} from '@emotion/core'
// import {color} from '../shared/styles'
import usWebDesignStandardsTheme from 'typography-theme-us-web-design-standards'

// todo tryout
// https://fonts.google.com/specimen/Raleway?selection.family=Raleway:200,400,600,800&selection.subset=latin-ext
// https://fonts.google.com/specimen/EB+Garamond

// https://github.com/KyleAMathews/typography.js
const typography = new Typography(usWebDesignStandardsTheme)

let theme = {
	
	body: {
		margin:			'40px',
		bg: 			'#ffffff'
	},
	
	text: {
		color: 			'#000000',
	},

	headline: {
		color: 			'#000000',
	},
	
	link: {
		color: 			'red'
	},
	
	typography: typography,
	
	css: theme => css`
		a,
		a:link,
		a:visited {
			color: ${theme.link.color};
			text-decoration: underline;
		}
		
		body {
			margin: ${theme.body.margin};
			background: ${theme.body.bg};
		}
		
		html,
		body,
	 	p,
	 	div,
	 	span,
	 	table, tr, th, td,
	 	ul,
	 	ol,
	 	dl,
	 	li,
	 	div {
			color: ${theme.text.color};
		}
		
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: ${theme.headline.color};
		}
	`
}

export default theme