
import {cloneDeep, get, set, forEach, merge as mergeFn, assign as assignFn} from 'lodash'


let appState
let base = {
	
	viewportHeight: 0, // see app.jsx
	viewportWidth: 0, // see app.jsx
	
	keys: {}, // see app.jsx
	
	scrollPage: false, // see scroll handler below
	scrollPageDelta: 0, // see scroll handler below
	scrollPageSize: 200, // see scroll handler below
	scrolledPastHeader: false, // see scroll handler below
	
	user: '',

	modal: '',

	
	apiError: '',
	
	touched: 0,
	
	route: {
		action: '',
		path: '',
		pathname: '',
		params: null,
		query: null
	}
}



export function init(mixin) {
	var state = cloneDeep(base)
	appState = assignFn(state, mixin)
	
	
	// someState.init();
	// etc...
	
	return appState
}



let changeListeners = []

export function onChange(fn) {
	changeListeners.push(fn)
}

function changed(path) {
	window.requestAnimationFrame(() => {
		forEach(changeListeners, c => c(appState, path))
	})
}

function setFn(path, v) {
	set(appState, path, v)
	changed(path)
}


export function update(path, v) {
	setFn(path, v)
}


export function updateCb(path, cb) {
	let v = get(appState, path)
	setFn(path, cb(v))
}


export function merge(path, v) {
	let val = get(appState, path)
	mergeFn(val, v)
	changed(path)
}

export function assign(path, v) {
	let val = get(appState, path)
	assignFn(val, v)
	changed(path)
}


export function getIn(path) {
	return get(appState, path)
}

export function touch() {
	console.log('touched')
	update('touch', appState.touch + 1)
	update('touched', appState.touched + 1)
}

export function touched() {
	touch()
}

export function startReq(name, id, info) {
	updateCb(name + 'Request', function(val) {
		return {
			id: id,
			status: 'started',
			info: info || val?.info,
			updated: new Date().getTime()
		}
	})
	return function() {
		endReq(name, id, info)
	}
}

export function endReq(name, id, info) {
	updateCb(name + 'Request', function(val) {
		var endTime = new Date().getTime(),
			took = 0
		
		if (val.id === id) {
			took = endTime - val.updated
		}
		
		return {
			id: id,
			status: 'finished',
			info: info,
			updated: endTime,
			took: took
		}
	})
}

let priorScrolledPastHeader = false,
	lastPos = document.documentElement.scrollTop || document.body.scrollTop,
	scrollPageSize = base.scrollPageSize

window.addEventListener('scroll', function(e) {
	// ReactDOM.flushSync(() => {
	let scrollTop = document.documentElement.scrollTop || document.body.scrollTop,
		scrollPage = Math.round(scrollPageSize / scrollTop),
		scrolledPastHeader = scrollTop > 50,
		newScrollPage = Math.round(scrollTop / scrollPageSize)
	
	if (newScrollPage !== scrollPage) {
		update('scrollTop', scrollTop)
		update('scrollPage', newScrollPage)
		update('scrollPageDelta', scrollTop - lastPos)
		scrollPage = newScrollPage
		lastPos = scrollTop
	}
	
	if (scrolledPastHeader !== priorScrolledPastHeader) {
		update('scrolledPastHeader', scrolledPastHeader)
		priorScrolledPastHeader = scrolledPastHeader
	}
	
	// console.log("scroll", e, scrollTop);
	// });
})

export default {
	init, onChange,
	get: getIn, update, updateCb, merge, assign, touch, touched,
	startReq, endReq
}