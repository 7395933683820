import Cookies from 'js-cookie'


Cookies.defaults = {
	path: '/',
	domain: '.junipernotebook.com',
	// domain: 'localhost',
	sameSite: 'strict',
	secure: true
}

const cookie = Cookies

window.cookie = cookie
export default cookie
