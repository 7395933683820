

export default {
	
	defaultTask: {
		interval: 1000,
		task: function(s) {
			console.log('default task')
		}
	}
}