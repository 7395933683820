
import history from './common/history/history'


let appRoutes = require('./appRoutes')

export function stringifyRoute(to, params, query) {
	return appRoutes.stringify(to, params, query)
}

export function stringifyRouteObj({name, params, query}) {
	console.log(name, params, query)
	return appRoutes.stringify(name, params, query)
}

export function navTo(to, params, query, replace) {
	let href = appRoutes.stringify(to, params, query)
	replace
		? history.replace(href)
		: history.push(href)
}