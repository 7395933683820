import React from 'react'
import {memoize} from 'lodash'
import createReactClass from 'create-react-class'
import {forceUpdateWithSubscription} from '../componentMixins/componentMixins'

let _mixins = {},
	componentCalled = false,
	debug


export default function component(displayName, mixins, render) {
	componentCalled = true
	var o = createDefaultArguments(displayName, mixins, render)
	
	debug && console.log('CREATE', '<' + displayName + '>', o)
	
	return createReactClass({
		displayName: displayName,
		mixins: o.mixins,
		render: o.render
	})
};

component.configure = function(state, subscribe, debug) {
	if (componentCalled) {
		throw new Error('OmniState Compoinent loaded before omnistate.init and or '
			+ 'omnistate.component.configure executed. Be sure to call omnistate.init before '
			+ 'loading any OmniState view component modules. BEWARE ES6 import will hoist '
			+ 'modules breaking this requirement so use require instead of import for '
			+ 'OmniState view components. This need only apply to the main app file where '
			+ 'omni.init is called.')
	}
	_mixins = forceUpdateWithSubscription(state, subscribe, debug)
}


// function getDisplayName(WrappedComponent) {
//	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
// }


// todo test and how to access the state as it is on the hoc not the wrapped
// maybe pass a getter that subscribes the view to be force rendered on update?
// maybe the subscription is renewed on each get and cleared on each render

// WIP
// // This function takes a component...
// class Provide extends React.Component {
//	constructor(props) {
//		let {component, propsFromStatePaths, ...props} = this.props,
//			displayName =  `Stated(${getDisplayName(WrappedComponent)})`,
//			o = createDefaultArguments(displayName),
//			fullProps = {...props};
//
//
//		Object.keys(propsFromStatePaths).forEach((path, key) => fullProps[key] = this[key])
//
//		componentCalled = true;
//		debug && console.log("CREATE", "<"+displayName+">", o);
//
//		this.fullProps = fullProps;
//		this.componentWillMount = o.mixins.componentWillMount;
//		this.componentWillUnmount = o.mixins.componentWillUnmount;
//		this.componentWillUpdate = o.mixins.componentWillUpdate;
//
//		super();
//	}
//
//	render() {
//
//		return <WrappedComponent {...fullProps} />;
//	}
// };


// provide maps app state to props of a standard react component via cursors
// the cursor name should be the prop name, the value is a selector string into app state pointing to the value
// props is a method of passing in props that do not derive from app state
function provideFn(Component, cursors, props = {}) {
	let displayName = `(${Component.displayName || Component.name})`
	
	return component(displayName, {cursors}, function() {
		let p = {...this.props, ...props}
		Object.keys(cursors).forEach(key => {
			(key in props) && console.log(`component: ${displayName} overwriting prop ${key} with cursor "${cursors[key]}"`)
			p[key] = this[key]
		})
		return <Component {...p}/>
	})
}

let provideMemoized = memoize(provideFn)

export function provide(Component, cursors, props) {
	return provideMemoized(Component, cursors, props)
}

function createDefaultArguments(displayName, mixins, render) {
	// (render)
	if (typeof displayName === 'function') {
		render = displayName
		mixins = []
		displayName = void 0
	}
	
	// (mixins, render)
	if (typeof displayName === 'object' && typeof mixins === 'function') {
		render = mixins
		mixins = displayName
		displayName = void 0
	}
	
	// (displayName, render)
	if (typeof displayName === 'string' && typeof mixins === 'function') {
		render = mixins
		mixins = []
	}
	
	// Else (displayName, mixins, render)
	if (!Array.isArray(mixins)) {
		mixins = [mixins]
	}
	
	mixins.unshift(_mixins)
	
	return {
		displayName: displayName,
		mixins: mixins,
		render: !debug ? render : function _render() {
			console.debug('<' + displayName + '> RENDERED')
			return render.call(this, this.props)
		}
	}
}