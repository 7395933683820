import request from 'superagent'
import noCache from 'superagent-no-cache'
import config from '../appConfig.js'
import {stringify, routes} from '../apiRoutes'


export function listNotes(token, email, handler) {
	let url = stringify(routes.notes, null, {email})
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}

