import {each, every, get, filter, some, set} from 'lodash'

/**
 * @param operations {Object} defines condtions for executing an operation see description below
 * @param n new value from change handler
 * @param o old value from change handler
 * @param changes the difference between n and o
 * @param eventNameSpace string, just what is says
 *
 * the following values make up and operations description object
 *
 * conditions
 *
 *		require:	optional array of state members that must all be truthy (uses grab.js notation)
 *					supports negation with leading ! eg. require jobRunId to be falsey = "!jobRunId"
 *
 * 		change:     treated the same as below, use one or the other
 *		changes:	optional array of state members, any of which must have changed (uses grab.js notation)
 *					supports negation with leading ! eg. require jobRunId to not have changed = "!jobRunId"
 *
 * operation: the function to execute if the above conditions are met
 *
 * eg.
 * var operations = {
		 *		fetchClients: {
		 *			require: ["clientId"],
		 *			changes: ["user"],
		 *			operation: function() {
		 *				clientService.fetchClients(state);
		 *			}
		 *		}
		 *	};
 */

var listeners = {},
	LKEY = '__listeners__'

export default {
	/**
	 * @param operations {Object} defines condtions for executing an operation see description below
	 * @param n new value from change handler
	 * @param o old value from change handler
	 * @param eventNameSpace (string) toplevel namespace for events
	 * @param changes the difference between n and o
	 *
	 * the following values make up and operations description object
	 *
	 * conditions
	 *
	 *		require:	optional array of state members that must all be truthy (uses grab.js notation)
	 *					supports negation with leading ! eg. require jobRunId to be falsey = "!jobRunId"
	 *
	 * 		change:     treated the same as below, use one or the other
	 *		changes:	optional array of state members, any of which must have changed (uses grab.js notation)
	 *					supports negation with leading ! eg. require jobRunId to not have changed = "!jobRunId"
	 *
	 * operation: the function to execute if the above conditions are met
	 *
	 * eg.
	 * var operations = {
		 *		fetchClients: {
		 *			require: ["clientId"],
		 *			changes: ["user"],
		 *			operation: function() {
		 *				clientService.fetchClients(state);
		 *			}
		 *		}
		 *	};
	 */
	run: function(operations, n, o, changes, eventNameSpace) {
		eventNameSpace = eventNameSpace || 'defaultNS'
		each(operations, function(op, name) {
			var opChanges = op.change || op.changes, // support change or changes
				requireMatch = !op.require,
				changeMatch = !opChanges

			// filter on requires
			if (op.require) {
				requireMatch = every(op.require, function(requireVal) {
					if (requireVal.substr(0, 1) === '!') {
						return requireVal && !get(n, requireVal.substr(1))
					} else {
						return requireVal && get(n, requireVal)
					}
				})
			}

			// filter on changes
			if (opChanges) {
				var ignoreChanges = filter(opChanges, c => c.substr(0, 1) === '!'),
					noIgnoredChanges = every(ignoreChanges, function(ignore) {
						return !get(changes, ignore.substr(1))
					}),
					changeMatches = some(opChanges, function(change) {
						if (change.substr(0, 1) !== '!') {
							return (changes && get(changes, change))
						}
					})
				
				// ignoreChanges.length && console.log("filter on changes", ignoreChanges, noIgnoredChanges, changeMatches);
				
				changeMatch = noIgnoredChanges && changeMatches
			}

			// if all conditions met, execute the operation
			if (requireMatch && changeMatch) {
				op.operation(n, o, changes, name, eventNameSpace)
			}
		})
	},

	// should be used just before the final render operation
	// this allows us to schedule events to happen after the user defined operations such as
	// targeted renders based on state changes
	// whereas the final render is a full app render, that happens when the url changes
	callMatching: {
		operation: function(n, o, c, eventName, eventNameSpace) {
			if (c) {
				callMatchingListeners(listeners[eventNameSpace], n, o, c)
				// console.log("direct render finished", listeners, eventNameSpace, listeners[eventNameSpace], new Date().getTime()-__start);
			}
		}
	},

	// configure the event nameSpace ahead of time so subscriers need not care about it
	eventSubscriber: function(eventNameSpace) {
		var self = this
		return function(path, cb) {
			return self.subscribe(eventNameSpace, path, cb)
		}
	},

	/**
	 * @param eventNameSpace (string) toplevel namespace for events
	 * @param path (string) period separated key path
	 * @param cb (function) to call e.g. to render a component
	 * @returns {Function} unsubscriber
	 */
	subscribe: function(eventNameSpace, path, cb) {
		// console.log("subscribe", path);
		var listenersPath = path + '.' + LKEY,
			eventListeners = listeners[eventNameSpace] = listeners[eventNameSpace] || {},
			subscribersNs = get(eventListeners, listenersPath)

		if (subscribersNs) {
			subscribersNs.push(cb)
		} else {
			subscribersNs = [cb]
			set(eventListeners, listenersPath, subscribersNs)
		}

		return function unsubFinishCb() {
			// console.log("unsubscribe", listenersPath);
			var subscribersNs = get(eventListeners, listenersPath),
				idx = subscribersNs.indexOf(cb)

			if (idx > -1) {
				subscribersNs.splice(idx, 1)
			}
		}
	}

}


// recursively traverse listeners for matches against changes
function getMatches(listeners, changes) {

	var m, matches = []

	for (var prop in listeners) {
		if (listeners.hasOwnProperty(prop) && prop !== LKEY && changes && prop in changes) {
			if (LKEY in listeners[prop]) {
				matches = matches.concat(listeners[prop][LKEY])
			}
			if (typeof changes[prop] === 'object' && prop in listeners) {
				// console.log({prop:prop, changes:changes, listener:listeners[prop], change:changes[prop]});
				m = getMatches(listeners[prop], changes[prop])
				matches = m ? matches.concat(m) : matches
			}
		}
	}

	return matches
}

function callMatchingListeners(listeners, n, o, c, r) {
	var called = []
	if (c) {
		getMatches(listeners, c).forEach(function(v) {
			if (called.indexOf(v) < 0) {
				called.push(v)
				v(n, o, c, r)
			}
		})
	}
}