import request from 'superagent'
import noCache from 'superagent-no-cache'
import config from '../appConfig.js'
import {stringify, routes} from '../apiRoutes'


export function signIn(email, redir, handler) {
	let url = stringify(routes.authenticate, null, {email, redir})
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}

export function claim(email, code, redir, handler) {
	let url = stringify(routes.claim, null, {email, code, redir})
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}

