
import React from 'react'
import Title from 'react-document-title'


export default function NotFound() {
	return (
		<Title title='Not Found'>
			<div id="notFound" className="page">
				<h1>404 Not Found</h1>
			</div>
		</Title>
	)
}

