
import {each} from 'lodash'


function runTasks(tasks, getState, interval) {
	var now = new Date().getTime(),
		s = getState && getState()

	// console.log("run tasks");
	each(tasks, t => {
		var last = t.last || now,
			delta = now - last

		// console.log("run task", delta, t);
		if (delta > (t.interval || interval)) {
			t.task(s)
			t.last = now
		} else if (!t.last) {
			t.last = now
		}
	})
}

export default function tsk(tasks) {

	var timer = null

	return {
		start: function(interval, getState) {
			clearInterval(timer)
			var ival = interval || 1000

			timer = setInterval(() => {
				runTasks(tasks, getState, ival)
			}, ival)
		},

		stop: function() {
			clearInterval(timer)
		},

		tasks: function() {
			return tasks
		}
	}
};