import {get} from 'lodash'

/**
 * forceUpdateWithSubscription
 *
 * @param stateObj {Object} app state object
 * @param subscribe function(keyPath, cb)
 * @returns component mixins {componentWillMount, componentWillUpdate, componentWillUnmount}
 *
 * the parent component should provide to the target component
 * a map of prop names as keys for cursors and period delimited path strings
 * on as 'cursors' property, e.g. <div cursors={{foo: 'bar.foo'}}></div>
 * in the above example: props.foo = state.reference(['bar', 'foo']).cursor()
 * updates will not be top-down component will watch the path ref and
 * force update upon any change
 */

export function forceUpdateWithSubscription(state, subscribe, debug) {
	
	// console.log("forceUpdateWithSubscription", getSerializedState, subscribe);

	return {
		
		componentWillMount: function() {
			var component = this,
				keyPaths = getKeyPaths(this)
			
			// console.log("componentWillMount", "<"+component.constructor.displayName+">");
			component._cancel = []
			
			function forceUpdate() {
				debug && console.log('<' + component.constructor.displayName + '>', 'forced update')
				component.__isMounted && component.forceUpdate()
			}

			for (var name in keyPaths) {
				
				// attach cursor to instance
				// console.log("state", state)
				component[name] = get(state, keyPaths[name].join('.'))

				// force update on change, store cleanup function for unmount
				// important, all subscriber callbacks should be === per component
				// this allows us to avoid multiple renders when multiple changes occur
				component._cancel.push(subscribe(keyPaths[name].join('.'), forceUpdate))
			}
			
			component.__isMounted = true
		},

		componentWillUpdate: function() {
			// update cursors
			var component = this,
				keyPaths = getKeyPaths(this)
			
			// console.log("componentWillUpdate", "<"+component.constructor.displayName+">");
			
			for (var name in keyPaths) {
				component[name] = get(state, keyPaths[name])
				// console.log("updating cursors", name, component[name]);
			}
		},

		componentWillUnmount: function() {
			// console.log("componentWillUnmount", "<"+this.constructor.displayName+">");
			// cancel observers
			this._cancel.forEach(function(fn) { fn() })
			this.__isMounted = false
		}
	}
};


/**
 * getReferences
 * expects either a member or prop of 'cursors' such as {foo: 'foo', baz: 'bar.baz'}
 * where the key is the name of a new member to add to the instance and
 * the value is a space delimited path within provided state
 * @param state json structure
 * @param component omniscient or react co
 * mponent instance
 * @returns {someName: state.reference(['some', 'path']), ...}
 */
/*
var references = {}

function getReferences(state, component) {
	var sources = {
			// support component defining own cursors
			componentDefs: component.cursors,
			// support parent defining cursors in props, will override prior cursors
			propDefs: (component.props && component.props.cursors)
		},
		refs = {}

	for (var source in sources) {
		var cursorDefs = sources[source]

		for (var name in cursorDefs) {
			var selector = cursorDefs[name]
			// in conjunction with forceUpdateOnState will attach references and cursors to the instance
			// it will also register reference observers that will force render and refresh cursors upon change
			references[selector] = refs[name] = (references[selector] || state.getIn(selector))
		}
	}

	return refs
}
*/

function getKeyPaths(component) {
	var sources = {
			// support component defining own cursors
			componentDefs: component.cursors,
			// support parent defining cursors in props, will override prior cursors
			propDefs: (component.props && component.props.cursors)
		},
		keyPaths = {}

	for (var source in sources) {
		var cursorDefs = sources[source]

		for (var name in cursorDefs) {
			var selector = cursorDefs[name]
			keyPaths[name] = selector.split('.')
		}
	}

	return keyPaths
}