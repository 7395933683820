
import React from 'react'
import history from '../history/history'
import appRoutes from '../../appRoutes'


export default (p) => {
	function getHref(to, params, query, href) {
		let route = appRoutes.byName[to]
		
		if (!href) {
			if (to && route) {
				return appRoutes.stringify(to, params, query)
			} else if (!route) {
				throw new Error('ERROR: Missing link data - href: ' + href + ' to:' + to + ' params: ' + params)
			}
		} else {
			return href
		}
	}
	
	function onClick(e) {
		let href = getHref(p.to, p.params, p.query, p.href),
			target = p.target && p.target !== '_self',
			aNewTab = e.metaKey || e.ctrlKey || target,
			anExternalLink = href.match(/^http/i)
		
		if (href && !aNewTab && !anExternalLink) {
			e.preventDefault()
			history.push(href)
		}
	}

	// Pluck off all non-standard props then pass the rest to the anchor tag
	let {children, href, to, params, query, ...linkProps} = p
	href = getHref(to, params, query, href)
	
	return <a {...linkProps} href={href} onClick={onClick}>{children}</a>
}