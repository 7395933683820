
import cookie from '../common/cookie/cookie'
import {navTo, stringifyRouteObj} from '../appRouter'
import {claim} from '../api/user'


export default {

	any: {
		operation: function(n, o, c) {
			console.log('changes', c)
		}
	},

	authRedirect: {
		operation: function(n, o, c) {
			let token = cookie.get('st'),
				{route} = n,
				{action, query} = route,
				routeStr = stringifyRouteObj(route),
				q = {redir: query.redir || (routeStr && encodeURIComponent(routeStr)) || undefined}


			console.log({action, routeStr, query})

			if (!token && !action.match(/login|claim/)) {
				navTo('Login', null, q, true)
			}
		}
	},

	authClaim: {
		operation: function(n, o, c) {
			let {route} = n,
				{action, query} = route,
				{redir, code, email} = query

			console.log({action, query})

			if (action === 'claim') {
				claim(email, code, redir)
			}
		}
	}
}