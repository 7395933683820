import {merge} from 'lodash'
// import {color, typography} from '../shared/styles'
// import {css} from '@emotion/core'
import lightTheme from './light'


let theme = merge({}, lightTheme, {
	body: {
		bg: '#000000'
	},
	text: {
		color: '#ffffff'
	},
	headline: {
		color: '#ffffff'
	}
})

export default theme