import React from 'react'
import If from '../../common/If/If'
import Link from '../../common/Link/Link'
import {signIn} from '../../api/user'
import cs from '../../common/cookie/cookie'


export default class Base extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			email: '',
			sent: false,
			busy: false,
			token: cs.get('token'),
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange(e) {
		this.setState({email: e.target.value})
	}

	handleSubmit(e, email, redir) {
		e.preventDefault()
		this.setState({email: '', busy: true})
		signIn(email, redir, (err, res) => {
			console.log(err, res)
			!err && this.setState({sent: true, busy: false})
		})
	}

	render() {
		let {route} = this.props,
			{action, query} = route,
			{code, redir} = query,
			isClaim = action === 'claim',
			{email, sent, token, busy} = this.state

		return (
			<div id="base" className="page">
				<If is={token}>
					<div>authenticated</div>
					<Link to="Profile">Profile</Link>
					<br/>
					<Link to="Pages">Pages</Link>
				</If>

				<If is={!isClaim && !token && !sent}>
					<form onSubmit={e => !busy && this.handleSubmit(e, email, redir)}>
						<input type="email" placeholder="your@email.com" value={email} onChange={this.handleChange} />
						<If is={busy}><button disabled="disabled">Working...</button></If>
						<If is={!busy}><button type="submit">Sign In</button></If>
					</form>
				</If>

				<If is={!isClaim && !token && sent}>
					<div>Check your email for sign-in link.</div>
					{/*	todo poll cookies here and update message to say you can close this tab now, heres some secureity info */}
				</If>

				<If is={isClaim}>
					<div>Authorizing Session...</div>
				</If>

			</div>
		)
	}
}

