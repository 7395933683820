import React from 'react'
import Title from 'react-document-title'
import {GlobalStyleFragment} from './common/JDS/shared/global'
import darkTheme from './common/JDS/themes/dark'
import lightTheme from './common/JDS/themes/dark'
import If from './common/If/If.jsx'
import events from './common/events/events'
import {router} from './views/router'
import cs from './common/cookie/cookie'


export default class App extends React.Component {

	topLevelClick(e) {
		events.emit('TOPLEVELCLICK', e)
	}

	topLevelMouseDown(e) {
		events.emit('TOPLEVELMOUSEDOWN', e)
	}

	render() {
		console.log('props', this.props)
		let p = this.props,
			token = cs.get('token'),
			route = p.route,
			nf = p.modal && p.modal.match('notfound'),
			notFound = nf && p.modal.split(':')[1],
			className = route.action,
			RouteHandler = router(route.action),
			activeTheme = p.theme === 'dart' ? darkTheme : lightTheme

		className += p.modal ? ' modal-open' : ''

		return (
			<Title title='App'>
				<GlobalStyleFragment theme={activeTheme}>
					<div className={className} onClick={this.topLevelClick} onMouseDown={this.topLevelMouseDown}>
						<div id="appContents" className="">
							<div id="main">

								<If is={!notFound}><RouteHandler route={route} token={token}/></If>

								<If is={notFound}>
									<div className="page notFoundContent">
										<br />
										<br />
										<br />
										<h1>Not Found</h1>
									</div>
								</If>

								<div id="modal"></div>

							</div>
						</div>
					</div>
				</GlobalStyleFragment>
			</Title>
		)
	}
}
