
import {isObject, isArray, isEmpty, isEqual, uniq, keys, forEach} from 'lodash'


export default function diff(template, override) {
	if (!isObject(override)) {
		return override
		// throw new Error("Missing or invalid argument")
	}

	// handle arrays
	if ((!isObject(template) || (isArray(override) || isArray(template))) && !isEqual(template, override)) {
		return override
	}

	var ret = {},
		oVal, tVal,
		kys = uniq(keys(template).concat(keys(override)))

	forEach(kys, function(name) {
		oVal = override[name]
		tVal = template[name]

		if (tVal && isObject(oVal) && !isArray(oVal)) {
			var _diff = diff(tVal, oVal)
			if (!isEmpty(_diff)) {
				ret[name] = _diff
			}
		} else if (!isEqual(tVal, oVal)) {
			ret[name] = oVal
		}
	})

	return ret
}


export function diffDetailed(template, override) {
	if (!isObject(override)) {
		return undefined
	}

	// handle arrays
	if ((!isObject(template) || (isArray(override) || isArray(template))) && !isEqual(template, override)) {
		return override
	}

	var added = {},
		removed = {},
		updated = {},
		hasAdded, hasRemoved, hasUpdated,
		ret, oVal, tVal, hasOVal, hasTVal,
		kys = uniq(keys(template).concat(keys(override)))

	forEach(kys, function(name) {
		hasOVal = (name in override)
		hasTVal = (name in template)
		oVal = hasOVal ? override[name] : undefined
		tVal = hasTVal ? template[name] : undefined

		if (tVal && isObject(oVal) && !isArray(oVal)) {
			// recursion path
			var dd = diffDetailed(tVal, oVal)
			if (dd) {
				if (dd.added) {
					added[name] = dd.added
					hasAdded = true
				}

				if (dd.removed) {
					removed[name] = dd.removed
					hasRemoved = true
				}

				if (dd.updated) {
					updated[name] = dd.updated
					hasUpdated = true
				}
			}


		} else if (!isEqual(tVal, oVal)) { // does handle NaN properly :-)
			if (!hasTVal) {
				added[name] = oVal
				hasAdded = true

			} else if (!hasOVal) {
				removed[name] = tVal
				hasRemoved = true

			} else {
				// todo somehow retain new and old values ?
				updated[name] = oVal
				hasUpdated = true
			}
		}
	})

	ret = hasAdded || hasRemoved || hasUpdated ? {} : undefined

	if (hasAdded) ret.added = added
	if (hasRemoved) ret.removed = removed
	if (hasUpdated) ret.updated = updated

	return ret
}