import React from 'react'
import {Global, css} from '@emotion/core'
import {withTheme, ThemeProvider} from 'emotion-theming'
import {GoogleFont} from 'react-typography'
// import '../../../../node_modules/@ionic/react/css/core.css'
// import {color, typography} from './styles'
// import {normalize} from './normalize'
import lightTheme from '../themes/light'


const makeGlobalStyles = theme => {
	console.log('makeGlobalStyles', theme)
	
	return css`
		/* typography */
		${theme.typography.toString()}
		/* theme */
		${theme.css(theme)}
	`
}

const GlobalStyles = withTheme(({theme}) => <Global styles={makeGlobalStyles(theme)} />)

export const GlobalStyleFragment = ({theme = lightTheme, children}) => {
	return (
		<ThemeProvider theme={theme}>
			<GoogleFont typography={theme.typography} />
			<GlobalStyles />
			{children}
		</ThemeProvider>
	)
}