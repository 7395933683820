import React from 'react'
import If from '../../common/If/If'
import Link from '../../common/Link/Link'
import cs from '../../common/cookie/cookie'


export default function({route}) {
	return (
		<div>
			route: {route.name}
		</div>
	)
}

