
// this file loaded in client and server so must be node compatible

let routeMatcher = require('route-matcher').routeMatcher,
	queryString = require('query-string'),
	appConfig = require('./appConfig.js')


let apiPath = appConfig.publicApiPath,
	matchers = {},
	routes = []

const apiRoutes = {
	authenticate:		apiPath + '/authenticate',
	user:				apiPath + '/user',
	docs: 				apiPath + '/docs',
	notes: 				apiPath + '/notes',
}


// create matchers and route objects for each route
Object.keys(apiRoutes).forEach(name => {
	let pattern = apiRoutes[name]
	matchers[pattern] = matchers[name] = routeMatcher(pattern) // lookup by name or route
	routes.push({name, route: pattern})
})


function stringify(name, params, query) {
	let encodedParams = {}
	
	Object.keys(params || {}).forEach(key => {
		encodedParams[key] = encodeURIComponent(params[key])
	})
	
	let	matcher = matchers[name],
		pathname = matcher && matcher.stringify(encodedParams),
		search = query ? queryString.stringify(query || {}) : ''
	
	return pathname + (search ? '?' + search : '')
}


function match(path) {
	let params,
		route = routes.find(route => {
			params = matchers[route.name].parse(path)
			return params
		})
	
	return {route, params}
}

module.exports = {
	routes: apiRoutes,
	match: match,
	stringify: stringify
}