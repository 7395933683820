
// this file loaded in client and server so must be node compatible

var routeMatcher = require('route-matcher').routeMatcher,
	queryString = require('query-string'),
	appConfig = require('./appConfig.js')


let baseRoute = appConfig.baseRoute,
	matchers = []

let validRoutes = [
	{route: `${baseRoute}/login`, 					name: 'Login', 				action: 'login'},
	{route: `${baseRoute}/claim`, 					name: 'Login Claim', 		action: 'claim'},
	{route: `${baseRoute}/`, 						name: 'Root', 				action: 'root'},
	{route: `${baseRoute}/profile`, 				name: 'Profile', 			action: 'profile'},
	{route: `${baseRoute}/pages`,					name: 'Pages',				action: 'pages'},
	{route: `${baseRoute}/pages/:docId`, 			name: 'Page', 				action: 'page'},

	// for development / testing / debugging - not exposed to the user ----------------------------------------------------------------------------------------
	{route: `${baseRoute}/components`, 				name: 'Components',			action: 'components'}
]

// todo support hash
let api = {
	routes: validRoutes,
	byName: {},
	byAction: {},
	stringify: (name, params, query) => {
		let uri, encodedParams = {}

		Object.keys(params || {}).forEach(key => {
			encodedParams[key] = encodeURIComponent(params[key])
		})

		validRoutes.find((v, i) => {
			if (v.name === name) {
				let pathname = matchers[i].stringify(encodedParams),
					search = queryString.stringify(query || {})
				uri = pathname + (search ? '?' + search : '')
			}
			return uri
		})

		return uri
	},
	match: path => {
		let params,
			route = validRoutes.find((v, i) => matchers[i].parse(path))

		return {route, params}
	}
}

validRoutes.forEach(function(v) {
	matchers.push(routeMatcher(v.route))
	api.byName[v.name] = v
	api.byAction[v.action] = v
})

module.exports = api

