import React, { useState, useMemo } from 'react'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'


const initialValue = [
	{
		children: [
			{ text: 'This is editable plain text, just like a <textarea>!' },
		],
	},
]


const Page = () => {
	const [value, setValue] = useState(initialValue)
	const editor = useMemo(() => withHistory(withReact(createEditor())), [])
	return (
		<div id="page" className="page">
			<h2>Page</h2>
			<Slate editor={editor} value={value} onChange={value => setValue(value)}>
				<Editable placeholder="Enter some plain text..." />
			</Slate>
		</div>
	)
}


export default Page
