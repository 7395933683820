
import {provide} from '../common/component/component'
import Login from './Login/Login'
import Page from './Page/Page'
import Pages from './Pages/Pages'
import Base from './Base/Base'
import Profile from './Profile/Profile'
import NotFound from './NotFound/NotFound'


let views = {
	// property names reflect the action value for the intended route in appRoutes.js
	// 1st view is passed when using router function
	// 2nd view is used for nested routes, it is passed when using subrouter function

	root:					[provide(Base, {route: 'route'})],
	login:					[provide(Login, {route: 'route', keys: 'keys'})],
	claim:					[provide(Login, {route: 'route', keys: 'keys'})],
	profile:				[Profile],
	pages:					[Pages, provide(Page, {keys: 'keys'})],
	missing:				[NotFound]
}

export function router(action) {
	let view = views[action] || views.missing
	return view[0]
}

export function subrouter(action) {
	let view = views[action] || []
	return view[1] || views.missing[0]
}
