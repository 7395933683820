import React from 'react'
import Link from '../../common/Link/Link'


export default function Profile() {
	return (
		<div id="page" className="page">
			<h2>Profile</h2>
			<Link to="Base">Home</Link>
		</div>
	)
}

